<template>
  <div class="details">
    <div class="closebox" @click="cancel()">
      <i class="el-icon-close"></i>
    </div>
    <div class="img">
      <!-- <div class="remotediabox"></div> -->
      <remotedia class="remotediabox" :src="radarsrc" />
    </div>
    <div class="img2">
      <div class="diobox">
        <m3u8video @cancel="dilogvideoclose" :m3u8videourl="sp_urlone" :m3u8mdcode="sp_MDcodeone"
          :m3u8videoname="titlename" :m3u8show="m3u8show"/>
      </div>
      <div class="diobox" v-if="sp_urltwo !== ''">
        <m3u8video @cancel="dilogvideoclose" :m3u8videourl="sp_urltwo" :m3u8mdcode="sp_MDcodetwo"
          :m3u8videoname="titlename" :m3u8show="m3u8show"/>
      </div>
      <!-- <div class="diobox">
        <dilogvideo @cancel="dilogvideoclose" />
      </div> -->
    </div>
  </div>
</template>

<script>
// import TitleBox from '../visualization/components/title/index.vue'
import m3u8video from './m3u8video.vue'
import remotedia from './remotedia.vue'
export default {
  props: ['vncurl', 'sp_url', 'sp_MDcode', 'm3u8videoname', 'm3u8show'],
  components: {
    // TitleBox
    m3u8video,
    remotedia
  },
  data () {
    return {
      isshow: false,
      radarsrc: null,
      // spshow: true,
      titlename: '',
      sp_urlone: '',
      sp_urltwo: '',
      sp_MDcodeone: '',
      sp_MDcodetwo: ''
    }
  },
  computed: {},
  watch: {
    m3u8videoname: {
      immediate: true,
      handler (newvalue, lodvalue) {
        // this.$nextTick(() => {
        // console.log(newvalue, 'zizujianop')
        this.titlename = newvalue
        // console.log(this.vodeoUrl, 'vodeoUrl')
        // })
      }
    },
    sp_url: {
      immediate: true,
      handler (newvalue, lodvalue) {
        // this.$nextTick(() => {
        // console.log(newvalue, 'zizujianop')
        // console.log(newvalue.search('_'), 'panduan')
        if (newvalue.search('_') === -1) {
          this.sp_urlone = newvalue

          // console.log(this.sp_urltwo, '一个')
        } else {
          const list = newvalue.split('_')
          // console.log(list, '多个')
          this.sp_urlone = list[0]
          this.sp_urltwo = list[1]
          this.sp_url = newvalue
        }
      }
    },
    sp_MDcode: {
      immediate: true,
      handler (newvalue, lodvalue) {
        // this.$nextTick(() => {
        // console.log(newvalue, 'zizujianop')
        // console.log(newvalue.search('_'), 'panduan')
        if (newvalue.search('_') === -1) {
          // this.sp_url = list[0]
          // this.sp_urltwo = list[1]
          this.sp_MDcodeone = newvalue

          // console.log(this.sp_urltwo, '一个')
        } else {
          const list = newvalue.split('_')
          // console.log(list, '多个')
          this.sp_MDcodeone = list[0]
          this.sp_MDcodetwo = list[1]
          // this.sp_url = newvalue
        }
        // console.log(this.sp_MDcode, this.sp_MDcodetwo, '-=')
      }
    },
    m3u8show: {
      immediate: true,
      handler (newvalue, lodvalue) {
        // this.$nextTick(() => {
        console.log(newvalue, 'newvalue')
        this.m3u8show = newvalue
        // })
      }
    }
  },
  methods: {
    dilogvideoclose () {
      // this.spshow = false
    },
    cancel () {
      this.$emit('cancel')
    },
    mouseover () {
      this.isshow = true
    },
    mouseout () {
      this.isshow = false
    }

  },
  created () {
    this.radarsrc = this.vncurl
  }
}
</script>
<style lang="less" scoped>
.details {
  width: 90%;
  height: 80%;
  position: absolute;
  top: 5%;
  left: 5%;
  background-size: 100% 100%;
  overflow: hidden;
  background: RGBA(10, 34, 55, 1);
  border: 2px solid #59a5ff;
  display: flex;
  justify-content: space-between;
  z-index: 3;
  .closebox {
    position: absolute;
    right: 0%;
    top: 0%;
    width: 30px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    text-align: center;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    z-index: 4;
  }
  .img {
    width: 58%;
    height: 100%;
    // background: #59a5ff;
    // background: #59a5ff;

    // display: flex;
    // justify-content: center;
    // align-items: center;
    // #remotediaid {
    //   // width: 100%;
    //   // background: red;
    //   // position: absolute;
    //   margin-top: -20%;
    //   margin-left: -48%;
    // }
  }
  .img2 {
    width: 40%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .diobox {
      width: 92%;
      height: 49%;
      margin: 1% 0 0 3%;
      // background: #59a5ff;
    }
    // background: #59a5ff;
  }
}
</style>
