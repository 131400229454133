<template>
  <iframe id="remotediaid" :src="src"></iframe>
</template>
<script>
export default {
  props: ['src'],
  data () {
    return {
      mysrc: ''
    }
  },
  created () {

  }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 1920px) {
  #remotediaid {
    width: 1940px;
    height: 1130px;
    transform: scale(0.5);
    transform-origin: 3% 16%;
  }
}
@media screen and (min-width: 1920px) {
  #remotediaid {
    width: 1600px;
    height: 900px;
    transform: scale(0.6);
    transform-origin: 3% 30%;
  }
}
//  style="width: 1940px; height: 1120px; transform: scale(0.5);transform-origin: 2% 0%;"
</style>
