<template>
  <div class="detador" :style="videostyle">
    <div class="detador-top" @click="handleFullScreen()">
      <!-- <TitleBox :titlename="titlename" v-show="m3u8show === 1"/> -->
      <div class="closebox" @click="close()">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="detador-center" id="fullscreen" ref="fullscreen" preload="auto">
      <easy-player ref="videoplay" :video-url="vodeoUrl">
      </easy-player>
      <img class="img2" src="../../assets/homeimg/s1.png" alt="" @mouseover="mouseover()"
        v-show="m3u8show === 0">
      <div class="imgbox" v-if="isshow" @mouseleave="mouseout()">
        <img class="img3" src="../../assets/homeimg/sk2.png" alt="">
        <div class="bigger" @mousedown="biggerbtn(1)" @mouseup="smallerbtn(1)">
        </div>
        <div class="smaller" @mousedown="biggerbtn(2)" @mouseup="smallerbtn(2)">
        </div>
        <div class="topbtn" @mousedown="Direction(1,0,3,1)" @mouseup="mouseup(1,0,3,0)"></div>
        <div class="leftbtn" @mousedown="Direction(3,3,0,1)" @mouseup="mouseup(3,3,0,0)"></div>
        <div class="ribtn" @mousedown="Direction(4,3,0,1)" @mouseup="mouseup(4,3,0,0)"></div>
        <div class="bombtn" @mousedown="Direction(2,0,3,1)" @mouseup="mouseup(2,0,3,0)"></div>
        <div class="ltbtn" @mousedown="Direction(5,3,3,1)" @mouseup="mouseup(5,3,3,0)"></div>
        <div class="rtbtn" @mousedown="Direction(7,3,3,1)" @mouseup="mouseup(7,3,3,0)"></div>
        <div class="lbbtn" @mousedown="Direction(6,3,3,1)" @mouseup="mouseup(6,3,3,0)"></div>
        <div class="rbbtn" @mousedown="Direction(8,3,3,1)" @mouseup="mouseup(8,3,3,0)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import EasyPlayer from '@easydarwin/easyplayer'
// import TitleBox from '../visualization/components/title/index.vue'
// import videojs from 'video.js'
import 'videojs-contrib-hls'
import '@videojs/http-streaming'
// import './EasyPlayer-element.min.js'
import 'video.js/dist/video-js.css' // videojs样式

export default {
  props: ['detailsshow', 'm3u8videourl', 'm3u8mdcode', 'm3u8videoname', 'm3u8show'],
  components: {
    // TitleBox,
    EasyPlayer

  },
  data () {
    return {
      fullscreen: false,
      isshow: false,
      titlename: '29°57′41.80″N',
      player: null,
      vodeoUrl: '',
      videostyle: {}

    }
  },
  computed: {

  },
  watch: {
    detailsshow: {
      handler (is) {

      }
    },
    m3u8videourl: {
      immediate: true,
      handler (newvalue, lodvalue) {
        // this.$nextTick(() => {
        console.log(newvalue, 'zizujianop')
        if (!newvalue) {
          this.$message.warning('未检测该视频路径')
        }
        this.vodeoUrl = newvalue
        // this.vodeoUrl = 'http://183.94.86.130:6611/3/5?DownType=5&jsession=&DevIDNO=100991766&FILELOC=2&FILESVR=7&FILECHN=0&FILEBEG=56821&FILEEND=57128&PLAYIFRM=0&PLAYFILE=D:/gStorage/RECORD_FILE/100991766/2022-11-17/100991766_0-221117-154701-155208-20010100.grec&PLAYBEG=0&PLAYEND=0&PLAYCHN=0&YEAR=22&MON=11&DAY=17.flv'

        // console.log(this.vodeoUrl, 'vodeoUrl')
        // })
      }
    },
    m3u8videoname: {
      immediate: true,
      handler (newvalue, lodvalue) {
        // this.$nextTick(() => {
        // console.log(newvalue, 'zizujianop')
        this.titlename = newvalue
        // console.log(this.vodeoUrl, 'vodeoUrl')
        // })
      }
    },
    m3u8mdcode: {
      immediate: true,
      handler (newvalue, lodvalue) {
        // this.$nextTick(() => {
        // console.log(newvalue, 'zizujianop')
        this.m3u8mdcode = newvalue
        // console.log(this.m3u8mdcode, 'zizujianop')
        // })
      }
    },
    m3u8show: {
      immediate: true,
      handler (newvalue, lodvalue) {
        // this.$nextTick(() => {
        this.m3u8show = newvalue
        console.log(this.m3u8show, 'this.m3u8show')
        // })
      }
    }
  },
  methods: {

    handleFullScreen () {
      //  全局全屏
      // const element = document.documentElement
      const element = document.getElementById('fullscreen')// 设置后就是 id==fullscreen 的容器全屏
      // 判断是否已经是全屏
      // 如果是全屏，退出
      // console.log(this.fullscreen)
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
        // console.log('已还原！')
      } else { // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
        // console.log('已全屏！')
      }
      // 改变当前全屏状态
      this.fullscreen = !this.fullscreen
    },
    // 全屏封装
    launchIntoFullscreen (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      }
    },

    mouseup (num, xsize, ysize, fThe) {
      this.Direction(num, xsize, ysize, fThe)
    },
    biggerbtn (num) {
      const obj = {
        direct: num,
        command: 1,
        channelId: this.m3u8mdcode,
        operateType: 1,
        step: 1
      }
      axios.post(this.$constant.zsqyUrl + '/zsqyapi/OperateCamera', obj, { headers: { database: '11NatureReserves' } })
        .then((res) => {
          // console.log(res, 'res')
        })
    },
    smallerbtn (num) {
      const obj = {
        direct: num,
        command: 0,
        channelId: this.m3u8mdcode,
        operateType: 1,
        step: 1
      }
      axios.post(this.$constant.zsqyUrl + '/zsqyapi/OperateCamera', obj, { headers: { database: '11NatureReserves' } })
        .then((res) => {
          // console.log(res, 'res')
        })
    },
    Direction (num, xsize, ysize, fThe) {
      const obj = {
        direct: num,
        command: fThe,
        channelId: this.m3u8mdcode,
        stepX: xsize,
        stepY: ysize
      }
      axios.post(this.$constant.zsqyUrl + '/zsqyapi/OperateDirect', obj, { headers: { database: '11NatureReserves' } })
        .then((res) => {
          // console.log(res, 'res')
        })
    },

    mouseover () {
      this.isshow = true
    },
    mouseout () {
      this.isshow = false
    },
    close () {
      this.$emit('close')
    }

  },

  created () {

  },
  mounted () {
    var loadingList = document.getElementsByClassName('easy-player-loading')
    // var url = require('../../assets/homeimg/vidolog.png')
    // console.log(loadingList, 'loadingList')
    // var url = '../../assets/homeimg/vidolog.png'
    // for (var key in loadingList) {
    loadingList.innerHTML = "<img src='https://common.cnblogs.com/images/wechat.png' style='width:100%;height:100%'>"
    // }

    // loadingList.innerHTML = `<img src=${url} style='width:100%;height:100%'>`
  },
  beforeDestroy () {
    // if (this.player != null) {
    //   this.player.dispose() // dispose()会直接删除Dom元素
    // }
  }
  // destroyed() {
  //           this.player.dispose()
  //    },
}
</script>
<style lang="less" scoped>
/deep/ .vjs-icon-placeholder {
  display: none;
}
.detador {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // position: relative;
  background: RGBA(10, 34, 55, 1);
  border: 2px solid #59a5ff;
  // display: flex;
  z-index: 3;

  &-top {
    width: 100%;
    height: 4%;
    margin-left: 1%;
    display: flex;

    .closebox {
      position: absolute;
      right: 0;
      top: 0%;
      width: 30px;
      height: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      text-align: center;
      font-family: SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
  }

  &-center {
    width: 100%;
    height: 96%;
    color: #ffffff;
    position: relative;

    .img1 {
      width: 100%;
      height: 100%;
    }

    .img2 {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 149px;
      right: 55px;
    }

    .imgbox {
      width: 112px;
      height: 300px;
      position: absolute;
      top: 64px;
      right: 15px;
      z-index: 99;

      // position: relative;
      .img3 {
        width: 112px;
        height: 153px;
      }

      .imgjt {
        width: 42px;
        height: 42px;
        margin-top: 10px;
        margin-left: 35px;
      }

      .imglp {
        width: 42px;
        height: 42px;
        margin-top: 15px;
        margin-left: 35px;
      }
      .topbtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz1.png');
        background-size: 100% 100%;
        position: absolute;
        top: 45px;
        right: 42px;
        cursor: pointer;
        z-index: 999;
      }

      .leftbtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz3.png');
        background-size: 100% 100%;
        position: absolute;
        top: 85px;
        left: 5px;
        cursor: pointer;
        z-index: 999;
      }

      .ribtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz4.png');
        background-size: 100% 100%;
        position: absolute;
        top: 85px;
        right: 5px;
        cursor: pointer;
        z-index: 999;
      }

      .bombtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz2.png');
        background-size: 100% 100%;
        position: absolute;
        top: 130px;
        right: 42px;
        cursor: pointer;
        z-index: 999;
      }
      .ltbtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz5.png');
        background-size: 100% 100%;
        position: absolute;
        top: 60px;
        left: 20px;
        cursor: pointer;
        z-index: 999;
      }
      .rtbtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz6.png');
        background-size: 100% 100%;
        position: absolute;
        top: 60px;
        right: 18px;
        cursor: pointer;
        z-index: 999;
      }
      .lbbtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz7.png');
        background-size: 100% 100%;
        position: absolute;
        top: 110px;
        left: 20px;
        cursor: pointer;
        z-index: 999;
      }
      .rbbtn {
        width: 24px;
        height: 24px;
        background: url('../../assets/homeimg/kz8.png');
        background-size: 100% 100%;
        position: absolute;
        top: 110px;
        right: 18px;
        cursor: pointer;
        z-index: 999;
      }
      .bigger {
        width: 24px;
        height: 24px;
        // background: red;
        background-size: 100% 100%;
        position: absolute;
        top: 10px;
        left: 15px;
        cursor: pointer;
        z-index: 999;
      }
      .smaller {
        width: 24px;
        height: 24px;
        // background: red;
        background-size: 100% 100%;
        position: absolute;
        top: 10px;
        right: 15px;
        cursor: pointer;
        z-index: 999;
      }
    }
    video {
      object-fit: contain;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    canvas {
      width: 70%;
      height: 110%;
    }
  }
}
</style>
